import React from 'react';
import { Image, Heading, Text, Link, View, ActionButton, TooltipTrigger, Tooltip, Flex, Avatar, Breadcrumbs, Item, Header, Content, TagGroup, TimeField, Icon, ActionMenu } from '@adobe/react-spectrum';
import { Bookmark as BookmarkType } from '../types/Bookmark';
import BookmarkIcon from '@spectrum-icons/workflow/Bookmark';
import BookmarkedIcon from '@spectrum-icons/workflow/BookmarkSingle';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import GraphTrend from '@spectrum-icons/workflow/GraphTrend';
import Date from '@spectrum-icons/workflow/Date';
import FolderOutline from '@spectrum-icons/workflow/FolderOutline';
import Project from '@spectrum-icons/workflow/Project';
import TaskList from '@spectrum-icons/workflow/TaskList';
import ChromeLogo from '../assets/chrome.svg'

interface BookmarkCardProps {
    bookmark: BookmarkType;
}

const BookmarkCard: React.FC<BookmarkCardProps> = ({ bookmark }) => {
    const handleBookmarkClick = () => {
        // Handle bookmarking logic here
    };

    const { title, text, url, image, isBookmarked } = bookmark;

    return (
        <View padding="size-150" borderWidth="thin" borderColor="light" borderRadius="regular" backgroundColor="gray-75">
            <Header>
                <TooltipTrigger>
                    <Flex>
                        <Avatar src="https://buymeacoffee.com/favicon.ico" alt={`favicon for website https://buymeacoffee.com`}></Avatar>

                        <Link marginStart={10} href="https://buymeacoffee.com" target='_blank' isQuiet>Buy Me a Coffee</Link>
                    </Flex>

                    <Tooltip>
                        https://buymeacoffee.com
                    </Tooltip>
                </TooltipTrigger>
            </Header>

            <Content marginTop="size-150">
                <View>
                    <Image src="https://i.imgur.com/Z7AzH2c.png" alt="Sky and roof" />

                    <View marginTop="size-150">
                        <Text>
                            Buy Me a Coffee is the best way for creators and artists to accept support and membership from their fans.
                        </Text>
                    </View>
                </View>
            </Content>

            <Flex marginTop="size-150">
                <TooltipTrigger>
                    <ActionButton isQuiet>
                        <Image width={20} src={ChromeLogo} alt="Chrome Logo" />
                    </ActionButton>

                    <Tooltip>
                        Imported from Chrome
                    </Tooltip>
                </TooltipTrigger>

                <TooltipTrigger>
                    <ActionButton isQuiet>
                        <Date />
                    </ActionButton>

                    <Tooltip>
                        Created 3 days ago
                    </Tooltip>
                </TooltipTrigger>

                <TooltipTrigger>
                    <ActionButton isQuiet>
                        <GraphTrend />
                    </ActionButton>

                    <Tooltip>
                        Among your top visited websites
                    </Tooltip>
                </TooltipTrigger>

                {/* <TooltipTrigger>
                    <ActionButton isQuiet>
                        <FolderOutline />
                    </ActionButton>

                    <Tooltip>
                        Belongs to Chrome bookmark folder 'Donations'
                    </Tooltip>
                </TooltipTrigger> */}

                <TooltipTrigger>
                    <ActionButton isQuiet>
                        <Project />
                    </ActionButton>

                    <Tooltip>
                        Found in Todoist project 'Release mybookmark.space'
                    </Tooltip>
                </TooltipTrigger>

                <TooltipTrigger>
                    <ActionButton isQuiet>
                        <TaskList />
                    </ActionButton>

                    <Tooltip>
                        Found as Todoist task 'some task name'
                    </Tooltip>
                </TooltipTrigger>

                <ActionMenu marginStart="auto" direction="top" align="end">
                    <Item>Copy to Clipboard</Item>
                    <Item>Pin to Top</Item>
                </ActionMenu>
            </Flex>
        </View>
    );
};

export { BookmarkCard };
