import React from 'react';
import { Flex, Header, SearchField, MenuTrigger, ActionButton, Menu, Item, Icon, Text, ToggleButton, ActionMenu, View } from '@adobe/react-spectrum';
import SortOrderDown from '@spectrum-icons/workflow/SortOrderDown';
import GroupBy from '@spectrum-icons/workflow/Group';
import ChromeLogo from '../assets/chrome.svg'
import TodoistLogo from '../assets/todoist.svg'
import EdgeLogo from '../assets/microsoft-edge.svg'
import NotionLogo from '../assets/notion.svg'

export const HeaderContainer: React.FC = () => {
    return (
        <Header>
            <View>
                <Flex alignItems="center" justifyContent="end" height="size-500">
                    <Flex gap="size-100">
                        <ToggleButton>
                            <Text>All <b>845</b></Text>
                        </ToggleButton>

                        <ToggleButton>
                            <Icon>
                                <img src={ChromeLogo} alt="logo" />
                            </Icon>

                            <Text>Chrome <b>325</b></Text>
                        </ToggleButton>

                        <ToggleButton>
                            <Icon>
                                <img src={TodoistLogo} alt="logo" />
                            </Icon>

                            <Text>Todoist <b>105</b></Text>
                        </ToggleButton>

                        <ToggleButton isDisabled>
                            <Icon>
                                <img src={EdgeLogo} alt="logo" />
                            </Icon>

                            <Text>Edge <b>105</b></Text>
                        </ToggleButton>

                        <ToggleButton isDisabled>
                            <Icon>
                                <img src={NotionLogo} alt="logo" />
                            </Icon>

                            <Text>Notion <b>65</b>
                            </Text>
                        </ToggleButton>
                    </Flex>

                    <SearchField width="size-5000" marginX="size-300" onSubmit={() => {}}></SearchField>

                    <Flex gap="size-100">
                        <MenuTrigger>
                            <ActionButton>
                                <Icon>
                                    <SortOrderDown />   
                                </Icon>

                                <Text>
                                    Sort By: Newest first
                                </Text>
                            </ActionButton>

                            <Menu onAction={(key) => alert(key)}>
                                <Item key="newest">Most visited first</Item>
                                <Item key="newest">Newest first</Item>
                                <Item key="oldest">Oldest first</Item>
                            </Menu>
                        </MenuTrigger>

                        <MenuTrigger>
                            <ActionButton>
                                <Icon>
                                    <GroupBy /> 
                                </Icon>

                                <Text>
                                    Group By: Hostname
                                </Text>
                            </ActionButton>

                            <Menu onAction={(key) => alert(key)}>
                                <Item key="newest">Hostname</Item>
                            </Menu>
                        </MenuTrigger>

                        <ActionMenu>
                            <Item>Donate</Item>
                            <Item>Give Feedback</Item>
                            <Item>Logout</Item>
                        </ActionMenu>
                    </Flex>
                </Flex>
            </View>
        </Header>
    );
};