import React from 'react';
import Masonry from 'react-masonry-css';
import { BookmarkCard } from '../BookmarkCard';
import { Bookmark } from '../../types/Bookmark';
import './Style.css';

interface BookmarkListProps {
    bookmarks: Bookmark[];
}

const BookmarkList: React.FC<BookmarkListProps> = ({ bookmarks }) => {
    const breakpointColumnsObj = {
        default: 7,
        1700: 6,
        1500: 5,
        1300: 4,
        1100: 3,
        900: 3,
        700: 2,
        500: 2,
        400: 1
    };

    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="bookmark-list"
            columnClassName="bookmark-list__col">
        
            {bookmarks.map((bookmark) => (
                <div>
                    <BookmarkCard key={bookmark.id} bookmark={bookmark} />
                </div>
            ))}
        </Masonry>
    );
};

export { BookmarkList };
