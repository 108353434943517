import React from 'react';
import { Flex, Link, Footer, View, Text, ActionButton, TooltipTrigger, Tooltip } from '@adobe/react-spectrum';
import Money from '@spectrum-icons/workflow/Money';

export const FooterContainer: React.FC = () => {
    return (
        <Footer>
            <View>
                <Flex alignItems="center" justifyContent="center" height="size-500" gap="size-100">
                    <Link isQuiet href="#">Project</Link>
                    <Link isQuiet href="#">Roadmap</Link>
                    <Link isQuiet href="#">Contribute</Link>
                    <Link isQuiet href="#">Give feedback</Link>

                    <View backgroundColor="yellow-500">
                        <TooltipTrigger isOpen>
                            <ActionButton staticColor="white" isQuiet>
                                <Money />

                                <Text>Buy me a Coffe</Text>
                            </ActionButton>

                            <Tooltip>Like the Product?</Tooltip>
                        </TooltipTrigger>
                    </View>
                </Flex>

                <Flex alignItems="center" justifyContent="center" height="size-400">
                    All rights reserved. &copy; {new Date().getFullYear()}
                </Flex>
            </View>
        </Footer>
    );
};