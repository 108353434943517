import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import { RegisterPage } from './pages/RegisterPage';
import { BookmarkListPage } from './pages/BookmarkListPage';
import { Grid, View, defaultTheme, Provider } from '@adobe/react-spectrum';
import { HeaderContainer } from './components/Header';
import { FooterContainer } from './components/Footer';
import {useHref, useNavigate} from 'react-router-dom';
import './App.css';

export const App: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Provider theme={defaultTheme} router={{ navigate, useHref }}>
            <Grid
                areas={{
                    base: [
                        'header',
                        'content',
                        'footer',
                    ],
                    M: [
                        'header',
                        'content',
                        'footer',
                    ],
                    L: [
                        'header',
                        'content',
                        'footer',
                    ]
                }}
                rows={['auto', '1fr', 'auto']}
                minHeight="100vh"
            >
                <View gridArea="header" padding="size-150">
                    <HeaderContainer />
                </View>

                <View gridArea="content" paddingX="size-150">
                    <Routes>
                        <Route path="/" element={<BookmarkListPage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                    </Routes>
                </View>

                <View gridArea="footer" padding="size-150">
                    <FooterContainer />
                </View>
            </Grid>
        </Provider>
    );
};
