import React from 'react';
import { BookmarkList } from '../components/BookmarkList/BookmarkList';
import mockData from '../mock_bookmarks_2.json';

export const BookmarkListPage: React.FC = () => {  return (
    <div>
      <BookmarkList bookmarks={mockData} />
    </div>
  );
};
